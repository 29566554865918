<template>
	<div class="page">
		<div class="w1000">
			<topHeader @goBack="sysgoback()"></topHeader>

			<div class="top-title-col">
				<span>{{bookName || ""}}</span>
				<span>共<span style="color: #f00">{{dataList.length}}</span>词</span>
			</div>

			<div class="flex-block">
				<!-- 列表 -->
				<div class="word-list">
					<div class="word-item" :class="{ 'word-checked': index === 1 }" v-for="(item, index) in dataList"
						:key="index">
						<!-- <img src="@/assets/static/linsten_icon.png" alt="" /> -->
						<!-- 单词 -->
						<div class="word-col" @click="handleword(item)">
							<div>{{item.word}}</div>
							<div class="word-desc">
								<span class="desc1" v-for="(sitem, sindex) in item.definitionList"
									:key="sindex">{{ sitem.part }}
									<span class="desc2" v-for="(mitem, mindex) in sitem.means"
										:key="mindex">{{ mitem }}
									</span>
								</span>
							</div>
						</div>
						<!-- <img src="@/assets/static/word_icon.png" alt="" /> -->
						<img @click="handleonecancel(index)" src="@/assets/static/star_icon_checked.png" alt="已收藏" />
						<!-- <img src="@/assets/static/star_icon.png" v-else desc="未收藏" alt="" /> -->
					</div>
				</div>
				<!-- 词卡 -->
				<wordCard v-if="isShowWordCard" class="word-card" :wordId="wordobj.wordId"></wordCard>
			</div>
			<!-- 空 -->
			<div class="data_empty_box" style="padding-top: 68px;" v-if="dataList.length<=0">
				<div class="icon"><img class="img100" :src="require('@/assets/static/kong.png')"></img></div>
				<div class="text">暂无数据</div>
			</div>
			<!-- 空 -->
		</div>
		<div class="block30"></div>
	</div>
</template>

<script>
	import {mapState} from "vuex";
	import topHeader from "@/components/topHeader/topHeader.vue";
	import wordCard from "@/components/wordCard/wordCard.vue";
	import {showLoading,hideLoading} from '@/common/loading.js';
	export default {
		components: {
			topHeader,
			wordCard,
		},
		data() {
			return {
				isShowWordCard: false, // 是否进入词卡页面
				wordobj:{},//点击的单词
				uid:"",
				bookName:"",
				dataList:[],//列表
				isFilter: false,
				allchecked:false,//全选按钮
			};
		},
		computed: {
			...mapState(["userInfo"]),
		},
		created() {
			if(this.$route.query&&this.$route.query.uid){
				if(this.$route.query.name){
					this.bookName = this.$route.query.name
				}
				this.uid = this.$route.query.uid
				this.getdatalist()
			}else{
				this.$alert('参数丢失，请返回重试！', '提示', {
				    confirmButtonText: '好的',
				    callback: action => {
						this.sysgoback()
				    }
				});
			}
		},
		methods: {
			changeType(index) {
				this.typeChecked = index;
			},
			//选择单词
			handleword(item){
				this.wordobj = item
				this.isShowWordCard = true
			},
			//单个取消收藏
			async handleonecancel(index){
				var _this = this
				let dataList = this.dataList
				var params = [{
					collectBookId: _this.uid,
					collectStatus: 0,//是否收藏 0否 1是
					wordId: dataList[index].wordId
				}]
				showLoading()
				var res = await this.$common.userCollectBooksWord(params)
				hideLoading()
				if(res){
					this.$message.success("取消收藏成功！")
					this.isFilter = false
					//刷新
					let newdataList = dataList.filter(item => item.wordId!=dataList[index].wordId);
					_this.dataList = newdataList
					_this.allchecked = false //全选按钮
				}
			},
			//取消收藏
			async handlecancel(){
				var _this = this
				var params = []
				var list = this.dataList
				list.slice().forEach((item, index) => {
					if(item.checked){
						params.push({
							collectBookId:_this.uid,
							collectStatus: 0,//是否收藏 0否 1是
							wordId: item.wordId
						})
					}
				});
				var res = await this.$common.userCollectBooksWord(params)
				if(res){
					this.$message.success("取消收藏成功！")
					this.isFilter = false
					//刷新
					let dataList = _this.dataList.filter(item => !item.checked);
					_this.dataList = dataList
					_this.allchecked = false //全选按钮
				}
			},
			//数据
			getdatalist(){
				var _this = this
				var params = {
					userUuid: this.userInfo.uuid,
					collectBookId:this.uid,
					pageSize:8888,
					currentPage:1,
				}
				showLoading()
				this.$http.post('app/word/userCollectBooksWord/index', params).then(function(res) {
					//数据处理
					hideLoading()
					if (res.code == 200) {
						var records = res.data.records
						records.forEach((item, index) => {
							item["definitionList"] = []
							item["checked"] = false
							try{
								item["definitionList"] = JSON.parse(item.definition)
							}catch(e){
								item["definitionList"] = []
								//TODO handle the exception
							}
						});
						_this.dataList = records
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import "@/css/main.scss";

	.top-title-col {
		display: flex;
		background: #e3f0ff;
		align-items: center;
		font-size: 14px;
		justify-content: space-between;
		height: 40px;
		padding-left: 16px;
		padding-right: 24px;
		border-radius: 8px;
		margin-top: 16px;
	}

	.filter-list {
		display: flex;
		flex-wrap: nowrap;
		margin: 32px 0px 0 24px;

		.checked-item {
			font-weight: bold;
			color: #222222 !important;
		}

		.filter-item {
			padding: 0 4px;
			height: 40px;
			flex-shrink: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			line-height: 40px;
			font-size: 16px;
			color: #666666;
			position: relative;
			margin-right: 28px;
			cursor: pointer;

			&:hover {
				color: #444;
			}
		}

		.line {
			height: 2px;
			width: 24px;
			position: absolute;
			bottom: 1px;
			left: 50%;
			transform: translateX(-50%);
			border-radius: 2px;
			background: #51d9a5;
		}
	}

	.word-list {
		max-height: 688px;
		flex: 1;
		overflow-y: auto;
		padding: 20px;
		margin-right: 10px;
		border-radius: 8px;
		box-shadow: 0px 3px 10px 0px #0000001a;
		flex-shrink: 0;
		height: 100%;
		.listbox{
			padding-bottom: 10px;
		}
		.time-item {
			height: 50px;
			border-bottom: solid 1px #e5e5e5;
			display: flex;
			align-items: center;
			cursor: pointer;
			transition: 0.3s all;
			color: #222;
			font-weight: bold;
			font-size: 16px;
	
			&:hover {
				border-bottom: solid 1px #67adff;
			}
	
			.words-num {
				margin-left: auto;
				margin-right: 2px;
				color: #666666;
				font-size: 14px;
				font-weight: normal;
			}
		}
	
		.word-checked {
			box-shadow: 0px 3px 15.5px 0px #0000001a;
			background: #fff !important;
		}
	
		.word-item {
			width: 100%;
			display: flex;
			align-items: center;
			height: 74px;
			margin-top: 16px;
			background: #f7f7f7;
			border-radius: 8px;
			cursor: pointer;
			transition: all 0.3s;
			border: #f7f7f7 1px solid;
			padding: 0 12px;
	
			.word-col {
				flex: 1;
				div {
					@include text-line(1);
					font-size: 16px;
					color: #222222;
					font-weight: bold;
					&:last-child {
						margin-top: 4px;
						font-size: 12px;
						color: #999999;
						font-weight: normal;
					}
				}
				.word-desc{
					width: 100%;
				}
			}
	
			img {
				transition: all 0.3s;
				margin-left: 12px;
				&:hover {
					transform: scale(1.05);
				}
			}
	
			&:hover {
				border: #e2e2e2 1px solid;
			}
		}
	
		&::-webkit-scrollbar {
			width: 6px;
		}
	
		&:hover {
			&::-webkit-scrollbar-thumb {
				background: #cfcfcf; // 滑块颜色
			}
		}
	
		&::-webkit-scrollbar-thumb {
			background: #efefef; // 滑块颜色
			border-radius: 5px; // 滑块圆角
		}
	
		&::-webkit-scrollbar-thumb:hover {
			background: #c2c2c2; // 鼠标移入滑块变红
		}
	
		&::-webkit-scrollbar-track {
			border-radius: 6px; // 轨道圆角
			background-color: #f7f7f7; // 轨道颜色
		}
	}

	.flex-block {
		display: flex;
		margin-top: 16px;
		.word-card {
			background: #f7f7f7;
		}
	}
</style>